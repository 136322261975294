
import moment from "moment";
import { defineComponent } from "vue";
import { Cascader, Table, Popover, Switch, Checkbox } from "ant-design-vue";
import {
  TagsFilled,
  SyncOutlined,
  SearchOutlined,
} from "@ant-design/icons-vue";
import {
  getProvince,
  masterList,
} from "@/api/taskManagement/taskManagement.js";
import axios from "axios";
import { taskListColumns } from "@/api/taskManagement/fixedData";
import Screen from "@/components/list/Screen.vue";
import TableTab from "@/components/list/TableTab.vue";
import Pagination from "@/components/list/Pagination.vue";

let { Group: CheckboxGroup } = Checkbox;
export default defineComponent({
  components: {
    Cascader,
    TagsFilled,
    SyncOutlined,
    SearchOutlined,
    Table,
    Popover,
    Switch,
    aCheckbox: Checkbox,
    aCheckboxGroup: CheckboxGroup,
    Screen,
    TableTab,
    Pagination,
  },
  data() {
    return {
      //各个筛选框的值待修改
      taskInfoTitleOrCode: "",
      prjNameOrCode: "",
      terminalNameOrCode: "",
      //省市区值
      regionValue: [],
      //后端接收省市区
      options: [],
      taskDimension: null,
      taskDimensionOptions: [
        {
          value: 1,
          label: "不限区域",
        },
        // {
        //   value: 2,
        //   label: "终端任务",
        // },
        // {
        //   value: 3,
        //   label: "区域任务",
        // },
      ],
      taskStatus: null,
      taskStatusOptions: [
        {
          value: 0,
          label: "编辑中",
        },
        // {
        //   value: 1,
        //   label: "待审核",
        // },
        {
          value: 2,
          label: "进行中",
        },
        // {
        //   value: 3,
        //   label: "发布失败",
        // },
        // {
        //   value: 4,
        //   label: "已终止",
        // },
        {
          value: 5,
          label: "已结束",
        },
      ],
      typeCode: null,
      typeCodeOptions: [
        {
          value: "CTT000ACTIVITY",
          label: "活动推广",
        },
      ],
      publishName: "",
      //日期
      dates: [],
      rangePicker: [], //发布时间
      rangePicker2: [], //报名截止时间
      //table的数据
      data: [],
      //table的列名字
      columns: taskListColumns,
      //复选框的选择结果
      selectedRowKeys: [],
      //总数据量
      total: 0,
      searchFlag: false,
      //拓展字段
      customData: [],
      keepCustomData: [],
      //table的布局
      scroll: { x: "100%" },
      //批量操作按钮加载
      batchLoading: false,
      loading: true,
      //table的样式
      batchStyle: "middle",
    };
  },
  methods: {
    //更改日期配合设置禁用日期
    onCalendarChange(val) {
      (this as any).dates.value = val;
    },
    //只能选择今天之前
    disabledDate(current) {
      return current && current > moment().endOf("day");
    },
    onOpenChange(open) {
      if (open) {
        (this as any).dates.value = [];
      }
    },
    //左侧的多选列禁用筛选
    getCheckboxProps(record) {
      if (!record.status) {
        return { disabled: true };
      }
    },
    //复选结果
    onSelectChange(selectedRowKeys: any) {
      this.selectedRowKeys = selectedRowKeys;
    },
    //省市区懒加载
    loadData(selectedOptions: any) {
      let targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true; // load options lazily
      if (targetOption.level === 1) {
        axios
          .get(
            `/api/base/v1/areaselect/city/find?provinceCode=${targetOption.code}`
          )
          .then((res: any) => {
            targetOption.loading = false;
            let newOptions = res.data.data.map((item) => {
              return {
                ...item,
                value: item.code,
                label: item.name,
                isLeaf: false,
                level: item.level,
              };
            });

            targetOption.children = newOptions;
            this.options = [...this.options];
          });
      }
      if (targetOption.level === 2) {
        let flag = this.options.filter((i: any) => {
          return i.level == targetOption.level;
        }).length;
        if (flag) {
          this.loadData(selectedOptions);
          return;
        }
        axios
          .get(
            `/api/base/v1/areaselect/area/find?cityCode=${targetOption.code}`
          )
          .then((res: any) => {
            targetOption.loading = false;
            let newOptions = res.data.data.map((item) => {
              return {
                ...item,
                value: item.code,
                label: item.name,
                level: item.level,
                isLeaf: true,
              };
            });
            targetOption.children = newOptions;
          });
      }
    },
    //跳转到编辑
    toDetails(id) {
      if (id === undefined) {
        id = "new";
      }
      (this as any).$router.push(`/taskHall/created/${id}`);
    },
    //跳转预览页面
    toPreview(id) {
      (this as any).$router.push(`/taskHall/details/${id}`);
    },
    changePagination(page) {
      this.selectedRowKeys = [];
      this.getData(page.pageSize, page.pageNum);
    },
    //获取列表
    getData(pageSize = 10, pageNum = 1) {
      this.loading = true;
      masterList({
        taskInfoTitleOrCode: this.taskInfoTitleOrCode,
        prjNameOrCode: this.prjNameOrCode,
        terminalNameOrCode: this.terminalNameOrCode,
        provinceCode: this.regionValue[0],
        cityCode: this.regionValue[1],
        areaCode: this.regionValue[2],
        taskDimension: this.taskDimension,
        typeCode: this.typeCode,
        publishStartTime: (this as any).rangePicker[0]
          ? moment((this as any).rangePicker[0]).format("YYYY-MM-DD") +
            " 00:00:00"
          : null,
        publishEndTime: (this as any).rangePicker[0]
          ? moment((this as any).rangePicker[1]).format("YYYY-MM-DD") +
            " 23:59:59"
          : null,
        getEndTimeStartTime: (this as any).rangePicker2[0]
          ? moment((this as any).rangePicker2[0]).format("YYYY-MM-DD") +
            " 00:00:00"
          : null,
        getEndTimeEndTime: (this as any).rangePicker2[0]
          ? moment((this as any).rangePicker2[1]).format("YYYY-MM-DD") +
            " 23:59:59"
          : null,
        publishName: this.publishName,
        taskStatus: this.taskStatus,
        pageSize: pageSize,
        pageNum: pageNum,
      })
        .then((res) => {
          let result = res.data;
          this.total = result.total;
          this.data = result.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //重置
    reset() {
      this.publishName = "";
      this.taskInfoTitleOrCode = "";
      this.prjNameOrCode = "";
      this.terminalNameOrCode = "";
      this.regionValue = [];
      this.taskDimension = null;
      this.taskStatus = null;
      this.typeCode = null;
      this.rangePicker = [];
      this.rangePicker2 = [];
      this.search();
      this.searchFlag = false;
    },
    //搜索
    search() {
      this.searchFlag = true;
      this.getData();
    },
  },
  mounted() {
    //获取省份
    getProvince().then((res: any) => {
      let newOptions = res.data.data.map((item) => {
        return {
          ...item,
          value: item.code,
          label: item.name,
          isLeaf: false,
        };
      });
      this.options = newOptions;
    });
    //获取终端列表信息
    this.getData(
      Number(
        sessionStorage.getItem(this.$route.path.replace(/\//g, "") + "Size")
      ) || 10,
      Number(
        sessionStorage.getItem(this.$route.path.replace(/\//g, "") + "Num")
      ) || 1
    );
  },
});
