// 终端 终端列表的固定表格第一行
export const taskListColumns = [{
    title: "#",
    dataIndex: "id",
    slots: { customRender: "serialNumber" },
    width: 70,
    align:'center',
    fixed: 'left',
},
{
    title: "任务名称/编码",
    dataIndex: "taskTitle",
    slots: { customRender: "taskTitle" },
    fixed: 'left',
    width: 180,
},
{
    title: "项目名称/编码",
    dataIndex: "prjName",
    slots: { customRender: "prjName" },
    width: 180,
}, 
// {
//     title: "终端名称/编码",
//     dataIndex: "terminalName",
//     slots: { customRender: "terminalName" },
//     width: 180,
// },
// {
//     title: "地址",
//     dataIndex: "address",
//     slots: { customRender: "province" },
//     width: 180,
// },
{
    title: "任务执行区域",
    dataIndex: "taskDimension",
    width: 100,
},
{
    title: "任务类型",
    dataIndex: "taskType",
    slots: { customRender: "taskType" },
    width: 100,
},
{
    title: "报名截止时间",
    dataIndex: "getEndTime",
    slots: { customRender: "getEndTime" },
    width: 100,
},
{
    title: "发布人",
    dataIndex: "publishName",
    width: 100,
},
{
    title: "发布时间",
    dataIndex: "publishTime",
    slots: { customRender: "publishTime" },
    width: 100,
},
{
    title: "任务状态",
    dataIndex: "taskStatus",
    slots: { customRender: "taskStatus" },
    width: 100,
},

{
    title: "操作",
    dataIndex: "handle",
    slots: { customRender: "handle" },
    fixed: 'right',
    width: 100,
},

]